import React from "react"

import { Logo } from '../components/Logo/Logo'

import '../styles/reset.css'
import styles from './index.module.css'

export default () => {
  return (
    <div className={styles.Page}>
      <Logo classes={styles.Logo} color="#fff"/>
      <div className={styles.Content}>
        <h1>Our website's currently undergoing a redesign, we'll be back with you just as soon as it's finished!</h1>
        <p>In the meantime, if you would like to get in touch just drop us an email at <a href="mailto:studio@limepants.com">studio@limepants.com</a> and we'll get right back to you.</p>
      </div>
    </div>
  )
}
